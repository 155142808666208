import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TrafficMapsView from './trafficMaps/TrafficMapsView'
import DifferenceMapsView from './differenceMaps/DifferenceMapsView'
import RoadNetworksView from './roadNetworks/RoadNetworksView'

class Sidebar extends Component {
  /**
   * Defines the element injected into the container
   */
  render () {
    return (
      <StyleSidebar>
        <RoadNetworksView
          map={this.props.map}
          logout={this.props.logout}
          addFeature={this.props.addFeature} />

        <TrafficMapsView
          map={this.props.map}
          logout={this.props.logout}
          addFeature={this.props.addFeature} />

        <DifferenceMapsView
          map={this.props.map}
          logout={this.props.logout}
          addFeature={this.props.addFeature} />
      </StyleSidebar>
    )
  }
}

const StyleSidebar = styled.div`
  position: fixed;
  height:93%;
  width:350px;
  z-index:997;

  background-color: #FFFFFF;
  font-family: 'Helvetica', 'Arial', sans-serif;
  color: #222A35;

  border-right:solid;
  border-right-width:1px;
  border-color:#222A35;

  overflow-y: auto;
`

/**
 * Validates props' types
 */
Sidebar.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default Sidebar
