import React from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup as MUIRadioGroup, FormControlLabel, FormControl } from '@mui/material'

const RadioGroup = ({ onChange, disabled, name, value, options, id }) => {
  return (
    <FormControl component="fieldset">
      <MUIRadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        row
      >
        {options.map((option, idx) => (
          <FormControlLabel
            key={`${id}_radio_${idx}`}
            value={option.value}
            control={<Radio disabled={disabled} />}
            label={option.label}
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  )
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string
}

export default RadioGroup
