import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import './BasemapSwitcher.css'
import { setMapStyle } from '../../actions/defaultActions'
import { BasemapStyles } from '../constants/BasemapStyles'
import { layerPrefix } from '../IdHelper'

const BasemapSwitcher = ({ map }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const mapStyle = useSelector(state => state.mapStyle)

  // Local state
  const [mapLayerVisible, setMapLayerVisible] = useState(true)

  const toggleBackground = (visibility) => {
    const layers = map.getStyle().layers

    layers.forEach(layer => {
      if (layer.id && !layer.id.startsWith(layerPrefix)) {
        map.setLayoutProperty(layer.id, 'visibility', visibility)
      }
    })

    setMapLayerVisible(!mapLayerVisible)
  }

  const switchStyle = (selection) => {
    if (selection.style) {
      // Save the current radsim layers to add them again when switching the style
      const radsimLayers = map.getStyle().layers.filter(layer => layer.id.startsWith(layerPrefix))
      const radsimSources = Object.entries(map.getStyle().sources)
        .filter(([id]) => id.startsWith(layerPrefix))

      // Warning "Expected value to be of type string, but found null instead."
      // can probably be ignored, happens with style "Street" and "Outdoor" only.
      map.setStyle(selection.style)

      map.once('styledata', () => {
        radsimSources.forEach(([id, source]) => {
          if (!map.getSource(id)) {
            map.addSource(id, source)
          }
        })

        radsimLayers.forEach(layer => {
          if (!map.getLayer(layer.id)) {
            map.addLayer(layer)
          }
        })
      })

      setMapLayerVisible(true)
    } else {
      const newVisibility = mapLayerVisible ? 'none' : 'visible'
      toggleBackground(newVisibility)
      setMapLayerVisible(!mapLayerVisible)
    }
    dispatch(setMapStyle(selection.key))
  }

  return (
    <div className="basemap-switcher">
      {Object.values(BasemapStyles).map((style) => (
        <a
          href="#"
          key={style.key}
          className={mapStyle === style.key ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation() // is this needed?
            switchStyle(style)
          }}
        >
          {style.label}
        </a>
      ))}
    </div>
  )
}

BasemapSwitcher.propTypes = {
  map: PropTypes.object.isRequired
}

export default BasemapSwitcher
