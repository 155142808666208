/**
 * This helps us to search for layers created by this app in Mapbox.
 */
export const layerPrefix = 'radsim_'

/**
 * The prefix used for traffic maps.
 */
const trafficPrefix = 'traffic_'

/**
 * The prefix used for difference maps.
 */
const diffPrefix = 'diff_'

export const trafficLayerPrefix = layerPrefix + trafficPrefix
export const differenceLayerPrefix = layerPrefix + diffPrefix

/**
 * Generate an id for a traffic map as the API has only ids for scenarios.
 *
 * @param {*} scenarioId The id of the scenario for the traffic.
 * @returns The id for the traffic map.
 */
export const getTrafficId = (scenarioId) => {
  return `${trafficPrefix}${scenarioId}`
}

/**
 * Generate an id for a difference map.
 *
 * The API now also has ids for differences, but the web-app still uses minuend and subtrahend ids.
 *
 * @param {*} minuendId The id of the minuend for the difference.
 * @param {*} subtrahendId The id of the subtrahend for the difference.
 * @returns The id for the difference map.
 */
export const getDiffId = (minuendId, subtrahendId) => {
  return `${diffPrefix}${minuendId}_${subtrahendId}`
}

/**
 * Generate an id for a layer. This helps us to search for layers created by this app in Mapbox.
 *
 * @param {*} id The id of the object to create a layer id for.
 * @returns The layer id.
 */
export const getLayerId = (id) => {
  return `${layerPrefix}${id}`
}
