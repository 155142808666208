/**
 * Temporary file which defines the color ranges used to visualize the traffic and differences.
 */

import { DifferenceColors, hexStringToRgbObject, TrafficColors } from './constants/Colors'

export function setTrafficColor (json) {
  for (let i = 0; i < json.features.length; i++) {
    const feature = json.features[i]
    const traffic = feature.properties.traffic
    let colorCode = TrafficColors.Unknown // default color, should not appear on map

    // The largest traffic of 'baseNet' is ~ 1250, thus this stepSize was selected:
    const stepSize = 200
    if (traffic === 0) {
      colorCode = trafficRgbCode(0, 7)
    } else if (traffic < 1 * stepSize) {
      colorCode = trafficRgbCode(1, 7)
    } else if (traffic < 2 * stepSize) {
      colorCode = trafficRgbCode(2, 7)
    } else if (traffic < 3 * stepSize) {
      colorCode = trafficRgbCode(3, 7)
    } else if (traffic < 4 * stepSize) {
      colorCode = trafficRgbCode(4, 7)
    } else if (traffic < 5 * stepSize) {
      colorCode = trafficRgbCode(5, 7)
    } else {
      colorCode = trafficRgbCode(6, 7)
    }

    feature.properties.color = colorCode
  }

  return json
}

export function setDifferenceColor (json) {
  for (let i = 0; i < json.features.length; i++) {
    const feature = json.features[i]
    const traffic = feature.properties.traffic
    let colorCode = DifferenceColors.Unknown // default color, should not appear on map

    // The largest traffic of 'baseNet' is ~ 1250, thus this stepSize was selected:
    if (traffic === 0) {
      colorCode = DifferenceColors.Neutral
    } else if (traffic < 0) {
      colorCode = negativeDifferenceRgbCode(traffic)
    } else {
      colorCode = positiveDifferenceRgbCode(traffic)
    }

    feature.properties.color = colorCode
  }

  return json
}

export function trafficRgbCode (step, stepSize) {
  if (step > stepSize - 1) {
    throw new Error('Step is out of bound, choose between 0 and ' + (stepSize - 1))
  }

  const neutral = hexStringToRgbObject(TrafficColors.Neutral)
  const traffic = hexStringToRgbObject(TrafficColors.HeavyTraffic)

  return calculateRgbCode(neutral, traffic, step, stepSize)
}

export function positiveDifferenceRgbCode (traffic) {
  const maxTraffic = 1200
  const fromColor = hexStringToRgbObject(DifferenceColors.LightPositive)
  const toColor = hexStringToRgbObject(DifferenceColors.Positive)

  if (traffic > maxTraffic) {
    return `rgb(${toColor.r},${toColor.g},${toColor.b})`
  }

  return calculateRgbCode(fromColor, toColor, traffic, maxTraffic)
}

export function negativeDifferenceRgbCode (traffic) {
  const maxTraffic = 1200
  const fromColor = hexStringToRgbObject(DifferenceColors.LightNegative)
  const toColor = hexStringToRgbObject(DifferenceColors.Negative)

  if (traffic < -maxTraffic) {
    return `rgb(${toColor.r},${toColor.g},${toColor.b})`
  }

  return calculateRgbCode(fromColor, toColor, -traffic, maxTraffic)
}

function calculateRgbCode (fromColor, toColor, value, maxValue) {
  const steps = maxValue - 1
  const stepSizeRed = (fromColor.r - toColor.r) / steps
  const stepSizeGreen = (fromColor.g - toColor.g) / steps
  const stepSizeBlue = (fromColor.b - toColor.b) / steps
  const red = Math.round(fromColor.r - stepSizeRed * value)
  const green = Math.round(fromColor.g - stepSizeGreen * value)
  const blue = Math.round(fromColor.b - stepSizeBlue * value)

  if (red < toColor.r || red > fromColor.r) {
    throw new Error('Red color share is out of bound: ' + red)
  }

  return `rgb(${red},${green},${blue})`
}
