import { useState, useRef } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { setRoadNetworkStyles, setEditMode, deleteTrafficMap, deleteDifferenceMap } from '../../../actions/defaultActions'
import { deleteDifference, deleteSimulation } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import RoadProperties from './RoadProperties'
import { RelationModifications } from '../../constants/RelationModifications'
import { useSelector } from 'react-redux'

const useMapFeatureHandlers = (map, differenceMaps, trafficMaps, logout) => {
  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  // Local state
  // Keep a handler-references to unregister them in exitEditMode
  const [handlers, setHandlers] = useState({
    segmentMouseMoveHandler: null,
    segmentMouseLeaveHandler: null,
    segmentClickHandler: null,
    mapClickHandler: null
  })

  // To access the current version of editMode in handlers.
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const editModeContainer = useRef()
  editModeContainer.current = editMode
  const roadNetworkStylesContainer = useRef()
  roadNetworkStylesContainer.current = roadNetworkStyles

  const switchStyleColors = (roadNetwork, roadNetworkStyles, map, dispatch) => {
    // Swap the colors for each style
    const newStyles = roadNetworkStyles.map(style => {
      const newStyle = cloneDeep(style) // original object may not be changed
      newStyle.colors = style.alternativeColors
      newStyle.opacity = style.alternativeOpacity
      newStyle.alternativeColors = style.colors
      newStyle.alternativeOpacity = style.opacity
      if (style.active) {
        map.setPaintProperty(roadNetwork.layerId, 'line-color', newStyle.colors)
        map.setPaintProperty(roadNetwork.layerId, 'line-opacity', newStyle.opacity)
      }
      return newStyle
    })
    dispatch(setRoadNetworkStyles(newStyles))
  }

  const isModifyingWay = (editMode) => editMode.wayEdit.clickedWayId !== null

  // Checks if the user is in a specific `RelationModifications` mode
  const isModifyingRelation = (editMode) =>
    editMode.relationEdit.clicked.relationId != null &&
    editMode.relationEdit.modification.type != null

  const createSegmentMouseLeaveHandler = (
    map,
    editModeContainer,
    dispatch,
    roadNetworkStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadNetworkStyles = roadNetworkStylesContainer.current
    if (!editMode.active || isModifyingWay(editMode)) {
      return
    }

    // Reset cursor
    map.getCanvas().style.cursor = ''

    const sourceId = editMode.roadNetwork.sourceId
    const active = roadNetworkStyles.find(style => style.active).key

    switch (active) {
      case RoadProperties.relations.key: {
        if (!isModifyingRelation(editMode)) {
          changeHoveredRelation(sourceId, editMode, null, dispatch)
        } else {
          const oldHoveredWayId = editMode.relationEdit.modification.hoveredWayId
          changeHoveredWay(sourceId, editMode, oldHoveredWayId, null, dispatch)
        }
        break
      } default: {
        changeHoveredWay(sourceId, editMode, editMode.wayEdit.hoveredWayId, null, dispatch)
      }
    }
  }

  const createSegmentMouseMoveHandler = (
    map,
    editModeContainer,
    dispatch,
    roadNetworkStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadNetworkStyles = roadNetworkStylesContainer.current
    if (!editMode.active || isModifyingWay(editMode)) {
      return
    }

    const sourceId = editMode.roadNetwork.sourceId
    const active = roadNetworkStyles.find(style => style.active).key
    const targetWay = e.features[0]

    switch (active) {
      case RoadProperties.relations.key: {
        if (!isModifyingRelation(editMode)) {
          // Set cursor
          const hasRelations = ('relations' in targetWay.properties)
          const newRelationInfo = relationInfo(targetWay, hasRelations)

          const oldHoveredRelation = editMode.relationEdit.hovered
          const sameRelationHovered = oldHoveredRelation.relationId && newRelationInfo &&
            newRelationInfo.osmId === oldHoveredRelation.relationId
          if (sameRelationHovered) {
            return // relation did not change and should still be highlighted
          }

          const clickedRelationId = editMode.relationEdit.clicked.relationId
          const hoveredIsClicked =
            newRelationInfo && clickedRelationId && newRelationInfo.osmId === clickedRelationId
          if (newRelationInfo === null || hoveredIsClicked) {
            changeHoveredRelation(sourceId, editMode, null, dispatch)
            // For some reasons the cursor does not reliably change for already clicked relations
            // Thus, we currently do not support to click already clicked relations
            return // has no relations or is not clickable
          }

          if (hasRelations) {
            map.getCanvas().style.cursor = 'pointer'
          }

          // Set new hovered relation
          const relationWayIds = findWayIds(editMode, newRelationInfo.osmId)
          const newRelation = {
            relationId: newRelationInfo.osmId,
            wayIds: relationWayIds,
            tags: newRelationInfo.tags
          }
          changeHoveredRelation(sourceId, editMode, newRelation, dispatch)
        } else {
          if (wayNotSelectable(targetWay.id, editMode.relationEdit)) {
            return
          }

          // Set cursor
          map.getCanvas().style.cursor = 'pointer'

          const oldHoveredWayId = editMode.relationEdit.modification.hoveredWayId
          changeHoveredWay(sourceId, editMode, oldHoveredWayId, targetWay.id, dispatch)
        }
        break
      }
      default: {
        // Set cursor
        map.getCanvas().style.cursor = 'pointer'

        changeHoveredWay(sourceId, editMode, editMode.wayEdit.hoveredWayId, targetWay.id, dispatch)
      }
    }
  }

  const createSegmentClickHandler = (
    map,
    editModeContainer,
    dispatch,
    roadNetworkStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadNetworkStyles = roadNetworkStylesContainer.current
    if (!editMode.active || isModifyingWay(editMode)) {
      return
    }

    const sourceId = editMode.roadNetwork.sourceId
    const active = roadNetworkStyles.find(style => style.active).key
    const targetWay = e.features[0]

    if (active !== RoadProperties.relations.key) {
      const oldClickedWayId = editMode.wayEdit.clickedWayId
      const targetWayId = targetWay.properties['@id']
      const newEditModeState = {
        ...editMode,
        wayEdit: {
          hoveredWayId: null,
          clickedWayId: targetWayId // automatically adds popup to dom
        }
      }
      dispatch(setEditMode(newEditModeState, map, editMode))

      changeHoveredWay(sourceId, editMode, targetWayId, null, dispatch)
      changeClickedWay(sourceId, editMode, oldClickedWayId, targetWayId, dispatch)
    } else if (active === RoadProperties.relations.key && !isModifyingRelation(editMode)) {
      const hasRelations = ('relations' in targetWay.properties)
      if (!hasRelations) {
        return
      }

      const newRelationInfo = relationInfo(targetWay, hasRelations)

      const relationWayIds = findWayIds(editMode, newRelationInfo.osmId)
      const clickedRelation = {
        relationId: newRelationInfo.osmId,
        wayIds: relationWayIds,
        tags: newRelationInfo.tags
      }

      const oldClickedRelation = editMode.relationEdit.clicked
      const newEditModeState = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          clicked: clickedRelation // automatically updates legend
        }
      }
      dispatch(setEditMode(newEditModeState, map, editMode))

      changeHoveredRelation(sourceId, editMode, null, dispatch)
      changeClickedRelation(sourceId, editMode, oldClickedRelation, clickedRelation, dispatch)
    } else if (active === RoadProperties.relations.key && isModifyingRelation(editMode)) {
      const selectedWayId = targetWay.properties['@id']
      if (wayNotSelectable(selectedWayId, editMode.relationEdit)) {
        return
      }

      const oldSelectedWayIds = editMode.relationEdit.modification.selectedWayIds
      const unSelectWay = oldSelectedWayIds.includes(selectedWayId)
      const newWayIds = unSelectWay
        ? oldSelectedWayIds.filter(id => id !== selectedWayId)
        : [...oldSelectedWayIds, selectedWayId]
      const newEditModeState = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          modification: {
            ...editMode.relationEdit.modification,
            selectedWayIds: newWayIds
          }
        }
      }
      dispatch(setEditMode(newEditModeState, map, editMode))
    } else {
      throw Error('Expected active style: ' + active)
    }
  }

  /**
   * Resets currently clicked relation.
   *
   * DISABLED: Also triggered when a way is clicked, which un-highlights clicked relations.
   */
  /* const createMapClickHandler = (
    map, dispatch, editModeContainer, roadNetworkStyles
  ) => (e) => {
    const editMode = editModeContainer.current
    if (!editMode.active ||
      (isModifyingWay(editMode) && isModifyingRelation(editMode))) {
      return
    }

    // Update state
    const active = roadNetworkStyles.find(style => style.active).key
    if (active === RoadProperties.relations.key) {
      const newEditModeState = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          clicked: {
            relationId: null, // automatically updates legend
            wayIds: [],
            tags: {}
          }
        }
      }
      dispatch(setEditMode(newEditModeState, map, editMode))
    }
  } */

  const enterEditMode = (e, roadNetwork, dispatch) => {
    const buttonClicked = e.target
    // Lock view
    setControlButtonsDisabled(true, buttonClicked) // FIXME
    setDropdownsDisabled(true) // FIXME
    switchStyleColors(roadNetwork, roadNetworkStyles, map, dispatch)

    // If we access `onClickHandler` etc. via `this.state.onClickHandler` the handler is not found
    const segmentMouseMoveHandler = createSegmentMouseMoveHandler(
      map,
      editModeContainer,
      dispatch,
      roadNetworkStylesContainer
    )
    const segmentMouseLeaveHandler = createSegmentMouseLeaveHandler(
      map,
      editModeContainer,
      dispatch,
      roadNetworkStylesContainer
    )
    const segmentClickHandler = createSegmentClickHandler(
      map,
      editModeContainer,
      dispatch,
      roadNetworkStylesContainer
    )
    // const mapClickHandler = createMapClickHandler()

    // Keep a handler-references to unregister them in exitEditMode
    setHandlers({
      segmentMouseMoveHandler,
      segmentMouseLeaveHandler,
      segmentClickHandler
      // mapClickHandler
    })

    // Update state
    const newEditModeState = {
      active: true,
      roadNetwork,
      wayEdit: {
        hoveredWayId: null,
        clickedWayId: null
      },
      relationEdit: {
        hovered: {
          relationId: null,
          wayIds: []
        },
        clicked: {
          relationId: null,
          wayIds: []
        },
        modification: {
          type: null,
          hoveredWayId: null,
          selectedWayIds: []
        }
      }
    }
    dispatch(setEditMode(newEditModeState, map, editMode))

    // Register handler
    const layerId = roadNetwork.layerId
    // "mousemove" fixes the bug when the cursor hovers betwee  two segments without leaving one
    map.on('mousemove', layerId, segmentMouseMoveHandler)
    map.on('mouseleave', layerId, segmentMouseLeaveHandler)
    map.on('click', layerId, segmentClickHandler)
    // map.on('click', mapClickHandler)

    // Attention! `map.on('click', e => {})` is also called when we click on a feature layer!
    // Thus, don't do anyhing there which could lead to a race condition with other onclick events!
  }

  const exitEditMode = async (e, dispatch) => {
    const buttonClicked = e.target
    const { roadNetwork } = editModeContainer.current
    switchStyleColors(roadNetwork, roadNetworkStyles, map, dispatch)

    // Remove handler (avoids multi-handler-calls)
    const {
      segmentMouseMoveHandler,
      segmentMouseLeaveHandler,
      segmentClickHandler,
      mapClickHandler
    } = handlers
    const layerId = roadNetwork.layerId
    map.off('mousemove', layerId, segmentMouseMoveHandler)
    map.off('mouseleave', layerId, segmentMouseLeaveHandler)
    map.off('click', layerId, segmentClickHandler)
    map.off('click', mapClickHandler)

    // Unhighlight edited elements on map (saved)
    const source = map.getSource(roadNetwork.sourceId)
    const sourceData = source._data
    sourceData.features.filter(element => element.properties.edited === true).forEach(element => {
      element.properties.edited = false
    })
    source.setData(sourceData)

    // Update state
    setHandlers({
      segmentMouseMoveHandler: null,
      segmentMouseLeaveHandler: null,
      segmentClickHandler: null,
      mapClickHandler: null
    })

    const newEditModeState = {
      active: false,
      roadNetwork: null,
      wayEdit: {
        hoveredWayId: null,
        clickedWayId: null
      },
      relationEdit: {
        hovered: {
          relationId: null,
          wayIds: []
        },
        clicked: {
          relationId: null,
          wayIds: []
        },
        modification: {
          type: null,
          hoveredWayId: null,
          selectedWayIds: []
        }
      }
    }
    dispatch(setEditMode(newEditModeState, map, editMode))

    // Remove DifferenceMaps bound to that RoadNetwork
    for (const differenceMap of differenceMaps) {
      if (differenceMap.roadNetworkId1 === roadNetwork.id ||
          differenceMap.roadNetworkId2 === roadNetwork.id) {
        await deleteDifference(
          dispatch,
          defaultErrorHandling,
          logout,
          differenceMap.roadNetworkId1,
          differenceMap.roadNetworkId2
        )
        map.removeLayer(differenceMap.layerId)
        map.removeSource(differenceMap.sourceId)
        dispatch(deleteDifferenceMap(differenceMap.id))
      }
    }

    // Remove TrafficMaps bound to that RoadNetwork
    for (const trafficMap of trafficMaps) {
      if (trafficMap.roadNetworkId === roadNetwork.id) {
        await deleteSimulation(
          dispatch,
          defaultErrorHandling,
          logout,
          trafficMap.roadNetworkId
        )
        map.removeLayer(trafficMap.layerId)
        map.removeSource(trafficMap.sourceId)
        dispatch(deleteTrafficMap(trafficMap.id))
      }
    }

    // Unlock view
    setControlButtonsDisabled(false, buttonClicked)
    setDropdownsDisabled(false)
  }

  // Only allow ways which are (not) in relationEdit.clicked.wayIds for remove (add)
  const wayNotSelectable = (selectedWayId, editModeRelation) => {
    const addingWays = editModeRelation.modification.type === RelationModifications.AddingWays
    const removingWays = editModeRelation.modification.type === RelationModifications.RemovingWays
    const wayInRelation = editModeRelation.clicked.wayIds.includes(selectedWayId)
    return (addingWays && wayInRelation) || (removingWays && !wayInRelation)
  }

  /**
   * Enables or disables all control buttons but one.
   *
   * @param newState True if the buttons should be disabled, false if enabled
   * @param exceptionButton The button to be kept untouched
   */
  const setControlButtonsDisabled = (newState, exceptionButton) => {
    const controlButtons = document.getElementsByClassName('controlButton')
    Array.prototype.slice.call(controlButtons)
      .filter(element => element !== exceptionButton)
      .forEach(button => {
        button.disabled = newState
      })
  }

  /**
   * Enables or disables all dropdowns.
   *
   * @param newState True if the dropdowns should be disabled, false if enabled
   */
  const setDropdownsDisabled = (newState) => {
    const buttons = document.getElementsByClassName('dropdownButton')
    Array.prototype.slice.call(buttons)
      .forEach(button => {
        button.disabled = newState
      })
  }

  const relationInfo = (eventWay, hasRelations) => {
    // Nested properties are stringified in sourceData, so we decode it
    // See https://github.com/mapbox/mapbox-gl-js/issues/2434
    return hasRelations
      ? JSON.parse(eventWay.properties.relations)[0] // select the first relation
      : null
  }

  const findWayIds = (editMode, relationId) => {
    const { roadNetwork } = editMode
    const source = map.getSource(roadNetwork.sourceId)
    const features = source._data.features
    return features.filter((feature) => {
      return ('relations' in feature.properties) &&
        feature.properties.relations.some((relation) => relation.osmId === relationId)
    }).map((feature) => feature.properties['@id'])
  }

  const changeHoveredRelation = (sourceId, editMode, newRelation, dispatch) => {
    const oldRelation = editMode.relationEdit.hovered
    if (newRelation == null || (newRelation && oldRelation.relationId !== newRelation.relationId)) {
      dispatch(setEditMode({
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          hovered: newRelation || {
            relationId: null,
            wayIds: [],
            tags: {}
          }
        }
      }, map, editMode))
    }
  }

  const changeClickedRelation = (sourceId, editMode, oldRelation, newRelation, dispatch) => {
    dispatch(setEditMode({
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        clicked: newRelation
      }
    }, map, editMode))
  }

  const changeHoveredWay = (sourceId, editMode, oldWayId, newWayId, dispatch) => {
    dispatch(setEditMode({
      ...editMode,
      wayEdit: {
        ...editMode.wayEdit,
        hoveredWayId: newWayId
      }
    }, map, editMode))
  }

  const changeClickedWay = (sourceId, editMode, oldWayId, newWayId, dispatch) => {
    dispatch(setEditMode({
      ...editMode,
      wayEdit: {
        ...editMode.wayEdit,
        clickedWayId: newWayId
      }
    }, map, editMode))
  }

  return {
    enterEditMode,
    exitEditMode,
    createSegmentMouseMoveHandler,
    createSegmentMouseLeaveHandler,
    createSegmentClickHandler,
    handlers
  }
}

export default useMapFeatureHandlers
