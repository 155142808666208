import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Dropdown from '../Dropdown'
import ControlButton from '../../ControlButton'
import { setDifferenceColor } from '../../colorRanges.js'
import { useSnackbarContext } from '../../SnackbarContext'
import { getLocalStorage, getWebsocketApiUrl } from '../../login/utils'
import { Endpoints } from '../../constants/Endpoints'
import { addDifferenceMap, setVisibleLayerId } from '../../../actions/defaultActions'
import { postDifference, getDifference } from '../../DataApi.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { LocalStorage } from '../../constants/LocalStorage.js'
import { Status } from '../../constants/Status'
import { MapLayers } from '../../constants/MapLayers.js'
import { getDiffId, getLayerId } from '../../IdHelper.js'

const StyleBox = styled.div`
  border:solid;
  border-width:1px;
  border-color:lightgrey;

  width:94%;
  margin:10px;
  padding:10px;

  font-size: 14pt;
  white-space: nowrap;

  display: ${props => props.$trafficMaps.length > 1 ? 'block' : 'none'};
`

const StyleHead = styled.div`
  font-size:18pt;
  color: #222A35;
  padding-bottom:10px;
`

const Wrapper = styled.div`
  padding-top: 10px;
`

const AddDifferenceMap = ({ map, logout, addFeature }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // Redux state
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const differenceMaps = useSelector((state) => state.differenceMaps)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  // Local state
  const [selectedDropdown1LayerObject, setSelectedDropdown1LayerObject] = useState(null)
  const [selectedDropdown2LayerObject, setSelectedDropdown2LayerObject] = useState(null)

  const handleSubmit = async () => {
    // ATTENTION: The customer wants to select the subtrahend first then the minuend
    // Our API expects the mathematical order, i.e.: minuend - subtrahend = diff
    // Thus, in hope to keep the confusion low we just swap both object here.
    const minuend = selectedDropdown2LayerObject
    const subtrahend = selectedDropdown1LayerObject

    const diffId = getDiffId(minuend.roadNetworkId, subtrahend.roadNetworkId)
    // The customer expects this order
    const differenceName = subtrahend.name + '; ' + minuend.name
    const roadNetworkId1 = minuend.roadNetworkId
    const roadNetworkId2 = subtrahend.roadNetworkId
    if (differenceMaps.find(element => element.roadNetworkId1 === roadNetworkId1 &&
      element.roadNetworkId2 === roadNetworkId2) !== undefined) {
      alert('Für diese Netz-Kombination existiert bereits eine Differenzkarte.')
      setSelectedDropdown1LayerObject(null)
      setSelectedDropdown2LayerObject(null)
      return
    }

    await difference(roadNetworkId1, roadNetworkId2, diffId, differenceName)

    setSelectedDropdown1LayerObject(null)
    setSelectedDropdown2LayerObject(null)
  }

  const difference = async (roadNetworkId1, roadNetworkId2, diffId, differenceName) => {
    await postDifference(dispatch, defaultErrorHandling, logout, roadNetworkId1, roadNetworkId2)
    subscribeToProgress(roadNetworkId1, roadNetworkId2, diffId, differenceName)
  }

  const subscribeToProgress = (roadNetworkId1, roadNetworkId2, diffId,
    differenceName) => {
    enqueueSnackbar('Berechnungsfortschritt: ...')

    // Subscribe to difference progress websocket
    const subProtocols = ['Bearer', getLocalStorage(LocalStorage.AccessToken)]
    const endpoint =
      getWebsocketApiUrl() + Endpoints.DifferenceProgress(roadNetworkId1, roadNetworkId2)
    const socket = new WebSocket(endpoint, subProtocols)
    socket.onmessage = async function (event) {
      const isNumber = Number.isInteger(Number(event.data))
      if (isNumber) {
        const progress = event.data
        enqueueSnackbar('Berechnungsfortschritt: ' + progress + ' %')
      } else {
        console.log('Unrecognized diff calulcation progress message: ' + event.data)
        // showSnackbar('Berechnung fehlgeschlagen')
      }
      if (event.data === '100') {
        // Load differnce
        const difference = (await getDifference(
          dispatch,
          defaultErrorHandling,
          logout,
          roadNetworkId1,
          roadNetworkId2
        )).data
        if (difference.data.status === Status.Running) {
          throw Error('Difference calculation still runnning, no results yet!')
        }
        const differenceData = setDifferenceColor(difference.data.data)

        // Update map
        const layerId = getLayerId(diffId)
        addFeature(map, layerId, differenceData, MapLayers.DifferenceMap)

        // Update redux store
        const differenceMap = {
          id: diffId,
          name: differenceName,
          layerId,
          sourceId: layerId,
          roadNetworkId1,
          roadNetworkId2
        }
        dispatch(addDifferenceMap(differenceMap))

        dispatch(setVisibleLayerId(differenceMap.layerId, map, visibleLayerId, roadNetworkStyles))
        closeSnackbar()
      }
    }
  }

  const submitIsDisabled = () => {
    const selection1 = selectedDropdown1LayerObject
    const selection2 = selectedDropdown2LayerObject
    if (selection1 === null || selection2 === null) {
      return true
    } else if (selection1.roadNetworkId === null || selection2.roadNetworkId === null) {
      return true
    } else if (selection1.id === selection2.id) {
      return true
    } else {
      return false
    }
  }

  const onDropdown1Change = (layerObject) => {
    setSelectedDropdown1LayerObject(layerObject)
  }

  const onDropdown2Change = (layerObject) => {
    setSelectedDropdown2LayerObject(layerObject)
  }

  return (
    <StyleBox $trafficMaps={trafficMaps}> {/* transient prop */}
      <StyleHead>Differenzkarte berechnen</StyleHead>

      <Dropdown
        triggerText='Netz 1 wählen'
        selectedLayerObject={selectedDropdown1LayerObject}
        layerObjects={trafficMaps.map(trafficMap => {
          const layerObject = {}
          layerObject.id = trafficMap.id
          layerObject.name = trafficMap.name
          layerObject.layerId = trafficMap.layerId
          layerObject.roadNetworkId = trafficMap.roadNetworkId
          return layerObject
        })}
        onChangeHandler={onDropdown1Change} />

      <Wrapper>
        <Dropdown
          triggerText='Netz 2 wählen'
          selectedLayerObject={selectedDropdown2LayerObject}
          layerObjects={trafficMaps.map(trafficMap => {
            const layerObject = {}
            layerObject.id = trafficMap.id
            layerObject.name = trafficMap.name
            layerObject.layerId = trafficMap.layerId
            layerObject.roadNetworkId = trafficMap.roadNetworkId
            return layerObject
          })}
          onChangeHandler={onDropdown2Change} />
      </Wrapper>

      <Wrapper>
        <ControlButton
          id='submitAddDifference'
          text='Differenz berechnen'
          icon='compare'
          disabled={submitIsDisabled()}
          onClick={handleSubmit}
          sx={{ marginTop: '9px' }} />
      </Wrapper>
    </StyleBox>
  )
}

/**
 * Validates props' types
 */
AddDifferenceMap.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default AddDifferenceMap
