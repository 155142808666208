import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LegendBox = styled.div`
  position:absolute;
  width: 155px;
  height: 340px;
  background-color: white;
  left: 360px;
  bottom: 80px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
  font-size: 15px;
  padding:10px;
`

const Line = styled.div`
  height: ${props => props.height};
  width: 30px;
  background-color: ${props => props.color};
  display: inline-block;
  margin-right:20px;
`

const LineWrapper = styled.div`
  margin-left: 10px;
  margin-top: 0px;
`

const Description = styled.div`
  display:inline-block;
  font-size: 12px;
`

class DifferenceLegend extends Component {
  render () {
    // const lowPositiveTrafficGreen = 'rgb(208,249,209)'
    const positiveTrafficGreen = 'rgb(0,235,0)'
    // const lowNegativeTrafficRed = 'rgb(249,208,209)'
    const negativeTrafficRed = 'rgb(235,0,0)'
    const neutralTrafficGrey = 'rgb(200,200,200)'
    const differenceThresholds = this.props.differenceThresholds
    return <LegendBox>
      <Header>Verkehrsmengen</Header>
      <LineWrapper>
        <Line height='12px' color={positiveTrafficGreen} />
        <Description>&gt; {differenceThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='10px' color={positiveTrafficGreen} />
        <Description>{differenceThresholds[3]} bis {differenceThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='8px' color={positiveTrafficGreen} />
        <Description>{differenceThresholds[2]} bis {differenceThresholds[3]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='6px' color={positiveTrafficGreen}/>
        <Description>{differenceThresholds[1]} bis {differenceThresholds[2]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='4px' color={positiveTrafficGreen} />
        <Description>{differenceThresholds[0]} bis {differenceThresholds[1]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='2px' color={positiveTrafficGreen}/>
        <Description>1 bis {differenceThresholds[0]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='2px' color={neutralTrafficGrey}/>
        <Description>0</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='2px' color={negativeTrafficRed}/>
        <Description>-1 bis -{differenceThresholds[0]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='4px' color={negativeTrafficRed} />
        <Description>-{differenceThresholds[0]} bis -{differenceThresholds[1]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='6px' color={negativeTrafficRed}/>
        <Description>-{differenceThresholds[1]} bis -{differenceThresholds[2]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='8px' color={negativeTrafficRed} />
        <Description>-{differenceThresholds[2]} bis -{differenceThresholds[3]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='10px' color={negativeTrafficRed} />
        <Description>-{differenceThresholds[3]} bis -{differenceThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='12px' color={negativeTrafficRed} />
        <Description>&lt; -{differenceThresholds[4]}</Description>
      </LineWrapper>
    </LegendBox>
  }
}

DifferenceLegend.propTypes = {
  differenceThresholds: PropTypes.array.isRequired
}

export default DifferenceLegend
