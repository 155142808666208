import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import './PaintSwitcher.css'
import { setRoadNetworkStyleActive } from '../../actions/defaultActions'
import { differenceLayerPrefix, trafficLayerPrefix } from '../IdHelper'
import { useSnackbarContext } from '../SnackbarContext.js'

const PaintSwitcher = ({ map }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbarContext()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  const setStyle = (style) => {
    if (editMode.relationEdit.clicked.relationId != null &&
      editMode.relationEdit.modification.type != null) {
      enqueueSnackbar('Beenden Sie zuerst die Routenbearbeitung', { autoHideDuration: 3000 })
      return
    }

    map.setPaintProperty(visibleLayerId, 'line-color', style.colors)
    map.setPaintProperty(visibleLayerId, 'line-opacity', style.opacity)
    map.setPaintProperty(visibleLayerId, 'line-dasharray', style.lineDashArray)

    // The action also unsets the clicked relation internally
    const previouslyActiveKey = roadNetworkStyles.find(s => s.active).key
    dispatch(setRoadNetworkStyleActive(style.key, map, previouslyActiveKey, editMode, dispatch))
  }

  const isTrafficShown = visibleLayerId !== null && visibleLayerId.startsWith(trafficLayerPrefix)
  const isDiffShown = visibleLayerId !== null && visibleLayerId.startsWith(differenceLayerPrefix)
  const isRoadNetworkShown = visibleLayerId !== null && !isTrafficShown && !isDiffShown

  return isRoadNetworkShown
    ? (
    <div className="layer-switcher">
      {roadNetworkStyles.map((style) => (
        <a
          href="#"
          key={style.key}
          className={style.active ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation() // is this needed?
            setStyle(style)
          }}
        >
          {style.label}
        </a>
      ))}
    </div>
      )
    : null
}

PaintSwitcher.propTypes = {
  map: PropTypes.object // may be null until map is intialized
}

export default PaintSwitcher
