import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import RoadProperties from '../sidebar/roadNetworks/RoadProperties'
import EditRelation from '../sidebar/roadNetworks/EditRelation'

const LegendBox = styled.div`
  position:absolute;
  width: 340px;
  height: 155px;
  background-color: white;
  left: 360px;
  bottom: 300px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
`

export const Header = styled.div`
  font-size: 15px;
  padding:10px;
`

const Stroke = styled.div`
  height: ${props => props.height};
  width: 30px;
  background-color: ${props => props.color};
  display: inline-block;
  margin-right:20px;
`

export const LineWrapper = styled.div`
  margin-left: 10px;
  margin-top: 0px;
`

export const Description = styled.div`
  display:inline-block;
  font-size: 12px;
`

const RoadLegend = ({ map, logout }) => {
  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  const activeStyle = roadNetworkStyles.find(style => style.active)
  const property = RoadProperties[activeStyle.key]
  const unifiedView = activeStyle.key === RoadProperties.unified.key && !editMode.active
  const relationsView = activeStyle.key === RoadProperties.relations.key && !editMode.active
  const relationsEdit = activeStyle.key === RoadProperties.relations.key && editMode.active

  return unifiedView || relationsView
    ? null
    : relationsEdit
      ? (
        // Relations edit mode
        <EditRelation map={map} logout={logout} />
        )
      : ( // Default
        <LegendBox>
          <Header>{property.label}</Header>
          {
            property.options.map(option => {
              return <LineWrapper key={option.value}>
                <Stroke height='6px' color={option.color} />
                <Description>{option.label}</Description>
              </LineWrapper>
            })
          }
        </LegendBox>
        )
}

/**
 * Validates props' types
 */
RoadLegend.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default RoadLegend
