import React from 'react'
import PropTypes from 'prop-types'
import RoadNetworkEntry from './RoadNetworkEntry'
import { useSelector } from 'react-redux'

/**
 * The road networks shown in the 'RoadNetworksView' of the sidebar.
 */
const RoadNetworks = ({ map, logout }) => {
  // Redux state
  const roadNetworks = useSelector((state) => state.roadNetworks)

  /**
   * Defines the element injected into the container
   */
  return (
    <div>
      {roadNetworks.length
        ? (
            roadNetworks.map(roadNetwork => (
              <RoadNetworkEntry
                key={roadNetwork.id}
                map={map}
                logout={logout}
                roadNetwork={roadNetwork}
              />
            ))
          )
        : (
        <p className="center">Keine Einträge vorhanden</p>
          )}
    </div>
  )
}

/**
 * Validates props' types
 */
RoadNetworks.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default RoadNetworks
