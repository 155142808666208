import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

/**
 * To turn different settings on or off.
 */
const CustomCheckbox = ({ id, label, value, checked, onChange, disabled, sx }) => {
  /**
   * The element injected into the container
   */
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          value={value} // currently unused
          sx={sx}
        />
      }
      label={label}
      sx={{
        '& .MuiFormControlLabel-label': {
          userSelect: 'none' // prevent text-selection when checking the checkbox
        }
      }}
    />
  )
}

CustomCheckbox.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  sx: PropTypes.object
}

export default CustomCheckbox
