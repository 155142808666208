import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../resources/logo.png'
import { AppBar, Toolbar, Typography } from '@mui/material'

/**
 * The top bar of the dashboard.
 */
const Header = ({ right, mobileView }) => {
  /**
   * Defines the element injected into the container
   */
  return (
    <AppBar position="static" sx={
      {
        height: '70px',
        backgroundColor: '#222A35',
        borderBottom: '1px solid #005DA9'
      }}>
      <Toolbar sx={{
        alignItems: 'center',
        height: '70px',
        '& img': {
          height: '27px',
          marginRight: '10px',
          marginLeft: mobileView ? '71px' : '0px'
        }
      }}>
        {/* left */}
        <img alt='RadSim Logo' src={logo} />

        {/* flexGrow 1 ensures the Typografie-Container uses the remaining space */}
        <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }} />

        {/* right */}
        {right}
      </Toolbar>
    </AppBar>
  )
}

/**
 * Validates props' types
 */
Header.propTypes = {
  right: PropTypes.object.isRequired,
  mobileView: PropTypes.bool.isRequired
}

export default Header
