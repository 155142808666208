import React, { Component } from 'react'

/**
 * Handles routing errors.
 */
class RoutingError extends Component {
  render () {
    return (
      <div>
        <p>URL existiert nicht</p>
      </div>
    )
  }
}

export default RoutingError
