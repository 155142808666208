import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RadioGroup from './RadioGroup'
import ControlButton from '../../ControlButton'
import RoadProperties from './RoadProperties'

const HALF_SIDEBAR_SIZE = 350 / 2
const HALF_POPUP_SIZE = 1100 / 2

const StylePopUp = styled.div`
  position:fixed;
  z-index:auto;

  height: 80vh;
  bottom:8vh;

  width: 90vw;
  max-width: 1100px;
  right:calc(50% - ${HALF_SIDEBAR_SIZE}px - ${HALF_POPUP_SIZE}px);

  font-family: 'Helvetica', 'Arial', sans-serif;
  padding: 20px;
  background-color: #FFFFFF;

  border:solid;
  border-width:2px;
  border-color:#222A35;
  border-radius: 4px;
  z-index:1;

  @media (max-width: 1650px) {
    width: 80vw;
    right: 10vw
  }

   @media (max-width: 1200px) {
    height: 90vh;
    bottom: 5vh;

    width: 80vw;
    right: 10vw
  }

  @media (max-width: 800px) {
    height: 98vh;
    bottom: 1vh;

    width: 98vw;
    right: 1vw
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`
const Column = styled.div`
  padding: 10px;
  &:first-child {
    flex: 1;
  }
  &:nth-child(2) {
    flex: 3;
  }
`

const Label = styled.p`
  font-weight:bold;
  width: 200px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const EditFeatureDialog = ({
  properties,
  handleFeatureChange,
  persistChanges,
  resetWayId
}) => {
  return (
    <StylePopUp>
      <Row>
        <Column>
          <Label>Merkmal</Label>
        </Column>
        <Column>
          <Label>Ausprägung</Label>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Zulässige Höchstgeschwindigkeit des MIV</Label>
        </Column>
        <Column data-testid = 'maxSpeedColumn'>
          <RadioGroup
            name='maxSpeed'
            options={RoadProperties.maxSpeed.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.maxSpeed}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Führungsform</Label>
        </Column>
        <Column data-testid = 'roadStyleColumn'>
          <RadioGroup
            name='roadStyle'
            options={RoadProperties.roadStyle.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.roadStyle}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Oberflächenbeschaffenheit</Label>
        </Column>
        <Column data-testid = 'surfaceColumn'>
          <RadioGroup
            name='surface'
            options={RoadProperties.surface.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.surface}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Qualität des Fahrbahnbelages</Label>
        </Column>
        <Column data-testid = 'surfaceQualityColumn'>
          <RadioGroup
            name='surfaceQuality'
            options={RoadProperties.surfaceQuality.options.map(option => ({
              label: option.label,
              value: option.value
            }))}
            withGap
            onChange={handleFeatureChange}
            value={properties.surfaceQuality}
            radioClassNames={'radio'}
          />
        </Column>
      </Row>

      <ButtonContainer>
        <ControlButton
          onClick={() => {
            persistChanges()
            resetWayId()
          }}
          text='Speichern'
          width='150px' />
        <ControlButton
          onClick={() => {
            resetWayId()
          }}
          text='Abbrechen'
          width='150px' />
      </ButtonContainer>
    </StylePopUp>
  )
}

EditFeatureDialog.propTypes = {
  properties: PropTypes.object.isRequired,
  handleFeatureChange: PropTypes.func.isRequired,
  persistChanges: PropTypes.func.isRequired,
  resetWayId: PropTypes.func.isRequired
}

export default EditFeatureDialog
