import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { setVisibleLayerId, deleteDifferenceMap } from '../../../actions/defaultActions'
import { deleteDifference } from '../../DataApi.js'
import CustomCheckbox from '../../CustomCheckbox'
import ControlButton from '../../ControlButton'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'

const StyleBox = styled.div`
  border:solid;
  border-width:1px;
  border-color:lightgrey;

  width:94%;
  margin:10px;
  padding:10px;

  font-size: 14pt;
  display: ${props => props.$differenceMaps.length > 0 ? 'block' : 'none'};
`

const StyleHead = styled.div`
  font-size:18pt;
  color: #222A35;
  padding-bottom:10px;
`

const DeleteButtonWrapper = styled.div`
  display: inline;
  padding-left: 10px;
`

const DifferenceMaps = ({ map, logout }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)
  const differenceMaps = useSelector((state) => state.differenceMaps)

  const onCheckboxChanged = (e, diffMapLayerId) => {
    const checkbox = e.target
    const makeVisible = checkbox.checked
    const newVisibleLayerId = makeVisible ? diffMapLayerId : null
    dispatch(setVisibleLayerId(newVisibleLayerId, map, visibleLayerId, roadNetworkStyles))
  }

  /**
   * Renders the diff maps list.
   * <p>
   * Must be a function or else it's only rendered once.
   */
  const differenceMapsList = (differenceMaps, editMode, visibleLayerId) => differenceMaps.length
    ? (
        differenceMaps.map(differenceMap => {
          const showDeleteButton = differenceMap.layerId === visibleLayerId
          return (
        <div key={differenceMap.id}>
          <CustomCheckbox
            id={differenceMap.id}
            label={differenceMap.name}
            value={differenceMap.layerId}
            checked={differenceMap.layerId === visibleLayerId}
            onChange={(e) => onCheckboxChanged(e, differenceMap.layerId)}
            disabled={editMode.active}
            sx={{ padding: '3px 9px 3px 9px' }}
          />
          {showDeleteButton
            ? (
            <DeleteButtonWrapper>
              <ControlButton
                id='deleteDifferenceMapButton'
                width='50px'
                height='32px'
                onClick={() => { deleteDiffMap(differenceMap) }} icon='delete' />
            </DeleteButtonWrapper>
              )
            : ''
          }
        </div>
          )
        })
      )
    : (
    <p className="center">Keine Einträge vorhanden</p>
      )

  const deleteDiffMap = async (differenceMap) => {
    await deleteDifference(
      dispatch,
      defaultErrorHandling,
      logout,
      differenceMap.roadNetworkId1,
      differenceMap.roadNetworkId2
    )
    map.removeLayer(differenceMap.layerId)
    map.removeSource(differenceMap.sourceId)
    dispatch(deleteDifferenceMap(differenceMap.id))
  }

  /**
   * Defines the element injected into the container
   */
  return (
    <StyleBox $differenceMaps={differenceMaps}> {/* transient prop */}
      <StyleHead>Differenzkarten</StyleHead>
      <div>
        {differenceMapsList(differenceMaps, editMode, visibleLayerId)}
      </div>
    </StyleBox>
  )
}

/**
 * Validates props' types
 */
DifferenceMaps.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default DifferenceMaps
