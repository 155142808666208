import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import AddRoadNetwork from './AddRoadNetwork'
import RoadNetworks from './RoadNetworks'
import EditFeature from './EditFeature'

const StyleBox = styled.div`
  border:solid;
  border-width:1px;
  border-color:lightgrey;

  width:94%;
  margin:10px;
  padding:10px;

  font-size: 14pt;
`

const StyleHead = styled.div`
  font-size:18pt;
  color: #222A35;
  padding-bottom:10px;
`

const RoadNetworksView = ({ map, logout, addFeature }) => {
  return (
    <div>
      <StyleBox>
        <StyleHead>Netze</StyleHead>

        <RoadNetworks
          map={map}
          logout={logout} />

        <AddRoadNetwork
          map={map}
          logout={logout}
          addFeature={addFeature} />
      </StyleBox>

      <EditFeature
        map={map}
        logout={logout} />
    </div>
  )
}

/**
 * Validates props' types
 */
RoadNetworksView.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default RoadNetworksView
