import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import LoginForm from './components/login/LoginForm'
import RoutingError from './components/RoutingError'
import { Callback } from './components/login/Callback'
import { fetchConfig } from './config/config.js'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { SnackbarProvider } from './components/SnackbarContext.js'
import { Colors } from './components/constants/Colors.js'

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Primary
    },
    secondary: {
      main: Colors.Secondary
    }
  },
  typography: {
    allVariants: {
      // eslint-disable-next-line max-len
      fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
      letterSpacing: '',
      color: Colors.Primary
    },
    h5: {
      color: Colors.Neutral
    },
    body1: {
      fontSize: '14px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *, *::before, *::after {
          box-sizing: border-box;
        }
      `
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.85rem',
          fontWeight: 'normal'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          // smaller icon
          padding: '16px',
          '& .MuiSvgIcon-root': {
            fontSize: 'medium'
          }
        }
      }
    }
  }
})

/**
 * Root component of this app. Wrapps logic and mockup. Handles the routing.
 */
const App = () => {
  const [defaultLocation, setDefaultLocation] = useState(null)

  useEffect(() => {
    fetchConfig().then(config => {
      setDefaultLocation(config.REACT_APP_DefaultLocation)
    })
  }, []) // executes only at the time of initial mounting

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* fix MuiCssBaseline border-box above to be applied */}
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<LoginForm />} />
            <Route path='/login' element={<LoginForm />} />
            <Route path="/login/:message" element={<LoginForm />} />
            <Route path="/callback" element={<Callback />} />
            <Route path='/map' element={ defaultLocation
              ? (<Dashboard
                accessToken={ process.env.REACT_APP_MAPBOX_TOKEN }
                defaultLocation={defaultLocation.slice(1, -1).split(', ').map(Number)}
              />)
              : null } />
            <Route path=':errorRoute' element={<RoutingError />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
