import React, { useRef, useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { OVERVIEW_ZOOM_LEVEL } from './MapBoxHelpers'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { addTrafficMap, addRoadNetwork, addDifferenceMap } from '../../actions/defaultActions.js'
import { debug } from '../login/utils.js'
import { getBaseNet, getScenarios, getDifferences, getScenario, getDifference, getSimulation } from '../DataApi.js'
import { setTrafficColor, setDifferenceColor } from '../colorRanges.js'
import { useSnackbarContext } from '../SnackbarContext.js'
import TrafficLegend from '../map/TrafficLegend.js'
import DifferenceLegend from '../map/DifferenceLegend.js'
import RoadLegend from '../map/RoadLegend.js'
import PaintSwitcher from './PaintSwitcher.js'
import { Status } from '../constants/Status.js'
import { defaultErrorHandling } from '../ErrorHandlingHelpers.js'
import { MapLayers } from '../constants/MapLayers.js'
import BasemapSwitcher from './BasemapSwitcher.js'
import { getDiffId, differenceLayerPrefix, getLayerId, getTrafficId, trafficLayerPrefix } from '../IdHelper.js'

/**
 * The initial zoom of the map.
 */
const initialZoom = 15

/**
 * Styled Components
 */
const Mapbox = styled.div`
  position: fixed; // relative to the viewport
  bottom: 0;
  right: 0;
  // 100vh does not work on mobile devices with position absolute
  height: calc(100% - ${props => props.$headerHeight});
  width: calc(100% - ${props => props.$sidebarSize});
  z-index: 0;
`

const MapContainer = ({
  map,
  setMap,
  logout,
  mobileView,
  accessToken,
  trafficThresholds,
  differenceThresholds,
  addFeature,
  defaultLocation
}) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // The document to render the Mapbox map in
  const mapContainer = useRef(null)

  // Local state
  // The current zoom level of the map
  const [zoomLevel, setZoomLevel] = useState(initialZoom)
  const zoomRef = useRef()
  zoomRef.current = zoomLevel

  // Redux state
  const visibleLayerId = useSelector(state => state.visibleLayerId)

  /**
   * Effects which depend on no state/prop (i.e. only executed on un-/mount).
   *
   * The first part is called when the component is inserted into the DOM.
   * The returned function is called when the component is removed from the DOM.
   *
   * Sets up the mapbox-map including the onClick etc. handlers.
   */
  useEffect(() => {
    if (map) return // initialize map only once

    // Required as it's not allowed to useEffect(async ()...
    const initializeMap = async () => {
      // Load data from backend
      const data = await loadData()
      enqueueSnackbar('Füge Daten zur Karte hinzu')

      // Setup map if not already initialized
      mapboxgl.accessToken = accessToken
      if (!map && mapContainer.current) {
        const newMap = new mapboxgl.Map({
          container: mapContainer.current,
          style: // TODO: is dev environment?
          // Using a local copy of the map style in dev mode enabled the app to run when offline.
          // You need to have the `mock-api` set-up and running in dev mode
          // We're serving the original style, i.e. the tiles are loaded from the mapbox server if
          // online.
          // ? 'mapbox/styles/light-v11'
          /*:*/
          'mapbox://styles/mapbox/light-v11?optimize=true',
          center: defaultLocation,
          zoom: initialZoom // At this moment always the default value
        })

        // Add zoom control
        const navControl = new mapboxgl.NavigationControl()
        newMap.addControl(navControl, 'top-left')

        // Add search bar
        newMap.addControl(
          new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            language: 'de-DE',
            mapboxgl
          })
        )

        // Add Scale
        const mapScale = new mapboxgl.ScaleControl({
          maxWidth: 120,
          unit: 'metric'
        })
        newMap.addControl(mapScale)

        // Set ZoomSpeed
        newMap.scrollZoom.setWheelZoomRate(1 / 100)

        newMap.on('zoomend', async () => {
          // Only update when zoom level crosses the overview zoom level
          if ((zoomRef.current < OVERVIEW_ZOOM_LEVEL && newMap.getZoom() >= OVERVIEW_ZOOM_LEVEL) ||
              (zoomRef.current > OVERVIEW_ZOOM_LEVEL && newMap.getZoom() <= OVERVIEW_ZOOM_LEVEL)) {
            setZoomLevel(newMap.getZoom())
          }
        })

        // Set map state when loaded + set state data
        newMap.on('load', async (e) => {
          // Add base net
          const layerId = getLayerId(data.baseNet.scenarioId)
          addFeature(newMap, layerId, data.baseNet.roadNetwork, MapLayers.RoadNetwork)
          const baseNetwork = {
            id: data.baseNet.scenarioId,
            name: 'Basisnetz',
            editable: false,
            layerId,
            sourceId: layerId
          }
          dispatch(addRoadNetwork(baseNetwork))

          // Add scenarios
          for (const scenario of data.scenarios) {
            // Update map
            const scenarioId = scenario._id
            const layerId = getLayerId(scenarioId)
            addFeature(newMap, layerId, scenario.roadNetwork, MapLayers.RoadNetwork)
            const roadNetwork = {
              id: scenarioId,
              name: scenario.name,
              editable: true,
              layerId,
              sourceId: layerId
            }
            dispatch(addRoadNetwork(roadNetwork))
          }

          // Add traffic maps
          for (const simulation of data.simulations) {
            // Update map
            const layerId = getLayerId(simulation.id)
            const simulationData = await simulation.data
            addFeature(newMap, layerId, simulationData, MapLayers.TrafficMap)

            // Update redux store
            const trafficMap = {
              id: simulation.id,
              name: simulation.name,
              layerId,
              sourceId: layerId,
              roadNetworkId: simulation.roadNetworkId
            }
            dispatch(addTrafficMap(trafficMap))
          }

          // Add difference maps
          for (const differences of data.differences) {
            // Update map
            const diffId = getDiffId(differences.roadNetworkId1, differences.roadNetworkId2)
            const layerId = getLayerId(diffId)
            const differenceData = await differences.data
            addFeature(newMap, layerId, differenceData, MapLayers.DifferenceMap)

            // Update redux store
            const differenceMap = {
              id: diffId,
              name: differences.name,
              layerId,
              sourceId: layerId,
              roadNetworkId1: differences.roadNetworkId1,
              roadNetworkId2: differences.roadNetworkId2
            }
            dispatch(addDifferenceMap(differenceMap))
          }

          // Set the map after everything else is set up
          setMap(newMap)

          closeSnackbar()
        })
      }
    }

    if (mapContainer.current) initializeMap({ setMap, mapContainer })
    // eslint-disable-next-line
  }, [/* map */]) // effect depends on no state/props: only run on un-/mount, not re-render

  const loadData = async () => {
    const simulations = []
    const scenarios = []
    const differences = []

    // Base network
    enqueueSnackbar('Lade Basisnetz')
    const baseNet = (await getBaseNet(dispatch, defaultErrorHandling, logout)).data
    const baseSimulation = await loadSimulation('Basisnetz', baseNet.scenarioId)
    if (baseSimulation !== null) {
      simulations.push(baseSimulation)
    }

    // Remaining scenarios
    const scenarioIds = (await getScenarios(dispatch, defaultErrorHandling, logout)).data
    const differencesIds = (await getDifferences(dispatch, defaultErrorHandling, logout)).data
    if (scenarioIds.error) {
      if (debug()) {
        console.log(scenarioIds.error)
      }
      enqueueSnackbar('Fehler beim Laden der Scenarios')
    }
    let i = 1
    for (const scenarioId of scenarioIds.identifiers) {
      enqueueSnackbar('Lade Plannetz ' + i + ' von ' + scenarioIds.identifiers.length)
      i++

      const scenario = (await getScenario(dispatch, defaultErrorHandling, logout, scenarioId)).data
      scenarios.push(scenario)

      // Load simulation results if existing)
      const simulation = await loadSimulation(scenario.name, scenarioId)
      if (simulation !== null) {
        simulations.push(simulation)
      }
    }

    // Difference maps
    for (const differencesId of differencesIds.identifiers) {
      // Load difference calculation results if existing
      const minuendId = differencesId.minuendId
      const subtrahendId = differencesId.subtrahendId
      const differenceResult =
        await (getDifference(dispatch, defaultErrorHandling, logout, minuendId, subtrahendId))
      const difference = differenceResult.data
      if (await difference.data.status === Status.Finished) {
        const minuendScenario = scenarios.find(s => s._id === minuendId)
        const subtrahendScenario = scenarios.find(s => s._id === subtrahendId)
        if ((minuendId !== baseNet.scenarioId && minuendScenario === undefined) ||
          (subtrahendId !== baseNet.scenarioId && subtrahendScenario === undefined)) {
          throw new Error('Minuend or subtrahend name for differences entry not found.')
        }
        const minuendName =
          minuendId !== baseNet.scenarioId ? minuendScenario.name : 'Basisnetz'
        const subtrahendName =
          subtrahendId !== baseNet.scenarioId ? subtrahendScenario.name : 'Basisnetz'

        const data = await setDifferenceColor(await difference.data.data)
        const diffId = getDiffId(minuendId, subtrahendId)
        differences.push({
          // The web-app still uses minuend/subtrahend ids. But the API now also has difference ids.
          id: diffId,
          name: subtrahendName + '; ' + minuendName, // The customer expects this order
          data,
          roadNetworkId1: minuendId,
          roadNetworkId2: subtrahendId
        })
      }
    }

    return {
      baseNet,
      scenarios,
      simulations,
      differences
    }
  }

  const loadSimulation = async (scenarioName, scenarioId) => {
    const simulateResult = await getSimulation(dispatch, defaultErrorHandling, logout, scenarioId)
    const simulate = simulateResult.data
    const simulation = simulate.data
    if ('simulationResult' in simulation &&
      simulation.simulationResult.status === Status.Finished) {
      const traffic = await setTrafficColor(await simulation.simulationResult.data)
      const trafficId = getTrafficId(simulation._id)
      return {
        // The API only has an id for the scenario, not the simulation
        id: trafficId,
        name: scenarioName,
        data: traffic,
        roadNetworkId: scenarioId
      }
    }
    return null
  }

  const isTrafficShown = visibleLayerId !== null && visibleLayerId.startsWith(trafficLayerPrefix)
  const isDiffShown = visibleLayerId !== null && visibleLayerId.startsWith(differenceLayerPrefix)
  const isRoadNetworkShown = visibleLayerId !== null && !isTrafficShown && !isDiffShown

  /**
   * Defines the element injected into the container
   */
  return (
    <div>
      <Mapbox
        ref={(el) => { mapContainer.current = el }}
        $headerHeight={'70px'}
        $sidebarSize={mobileView ? '0px' : '350px'} />
      {
        <PaintSwitcher map={map}/>
      }
      {
        // Show Legend and Layer Switcher
        isRoadNetworkShown
          ? <RoadLegend map={map} logout={logout} />
          : isTrafficShown && trafficThresholds != null
            ? <TrafficLegend trafficThresholds={trafficThresholds} />
            : isDiffShown && differenceThresholds != null
              ? <DifferenceLegend differenceThresholds={differenceThresholds} />
              : null
      }
      {
        map !== null
          ? <BasemapSwitcher map={map} />
          : null
      }
    </div>
  )
}

/**
 * Validates props' types
 */
MapContainer.propTypes = {
  map: PropTypes.object,
  setMap: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  accessToken: PropTypes.string.isRequired,
  defaultLocation: PropTypes.array.isRequired,
  trafficThresholds: PropTypes.array,
  differenceThresholds: PropTypes.array,
  addFeature: PropTypes.func.isRequired
}

export default MapContainer
