import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setEditMode } from '../../../actions/defaultActions'
import { RelationModifications } from '../../constants/RelationModifications'
import RoadProperties from './RoadProperties'
import { addRelationColor, RelationColors, RoadNetworkColors } from '../../constants/Colors'
import { patchScenario } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import EditRelationLegend from './EditRelationLegend'

const EditRelation = ({ map, logout }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  const onAddWays = () => {
    setModification({
      type: RelationModifications.AddingWays,
      hoveredWayId: null,
      selectedWayIds: []
    })
    setPaintColor(RelationColors.Add, RelationColors.HoveredAdd)
  }

  const onRemoveWays = () => {
    setModification({
      type: RelationModifications.RemovingWays,
      hoveredWayId: null,
      selectedWayIds: []
    })
    setPaintColor(RelationColors.Remove, RelationColors.HoveredRemove)
  }

  const onSave = () => {
    sendChangesToApi()

    applyToMapSource()

    setModificationAndClickedWayIds(
      newWayIds(),
      { type: null, hoveredWayId: null, selectedWayIds: [] }
    )

    resetPaintColor()
  }

  const onCancel = () => {
    setModification({ type: null, hoveredWayId: null, selectedWayIds: [] })
    resetPaintColor()
  }

  /**
   * Needs to be one call or else the second call will undo the first call.
   *
   * @param {*} newWayIds The new wayIds to be set.
   * @param {*} modification The new modification to be set.
   */
  const setModificationAndClickedWayIds = (newWayIds, modification) => {
    dispatch(setEditMode({
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        clicked: {
          ...editMode.relationEdit.clicked,
          wayIds: newWayIds
        },
        modification
      }
    }, map, editMode))
  }

  const setModification = (modification) => {
    if (modification === null) {
      throw new Error('Modification cannot be null')
    }
    dispatch(setEditMode({
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        modification
      }
    }, map, editMode))
  }

  const setPaintColor = (selectColor, hoveredColor) => {
    const color = [
      'case',
      ['boolean', ['feature-state', 'hovered'], false],
      hoveredColor,
      ['boolean', ['feature-state', 'selected'], false], // highest priority
      selectColor,
      ['boolean', ['feature-state', 'clicked'], false], // To keep the clicked relation color
      RoadNetworkColors.Clicked,
      ['get', 'relationColor']
    ]
    map.setPaintProperty(editMode.roadNetwork.layerId, 'line-color', color)
  }

  const resetPaintColor = () => {
    const style = roadNetworkStyles.find(style => style.key === RoadProperties.relations.key)
    map.setPaintProperty(editMode.roadNetwork.layerId, 'line-color', style.colors)
  }

  const sendChangesToApi = async () => {
    await patchScenario(
      dispatch,
      defaultErrorHandling,
      logout,
      editMode.roadNetwork.id,
      getChangeset()
    )
  }

  const getChangeset = () => {
    const relationId = editMode.relationEdit.clicked.relationId
    const modification = editMode.relationEdit.modification
    const changeset = { 'element-id': 'relation/' + relationId }
    if (modification.type === RelationModifications.AddingWays) {
      changeset.added = modification.selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    } else if (modification.type === RelationModifications.RemovingWays) {
      changeset.deleted = modification.selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    }
    return changeset
  }

  const applyToMapSource = () => {
    const source = map.getSource(editMode.roadNetwork.sourceId)
    const sourceData = source._data
    const updatedFeatures = addRelationColor({
      type: 'FeatureCollection',
      features: getUpdatedFeatures()
    })
    source.setData({
      ...sourceData,
      features: updatedFeatures.features
    })
  }

  const newWayIds = () => {
    const modification = editMode.relationEdit.modification

    switch (modification.type) {
      case RelationModifications.AddingWays: {
        const updatedWayIds = [
          ...editMode.relationEdit.clicked.wayIds,
          ...modification.selectedWayIds
        ]
        return updatedWayIds
      }
      case RelationModifications.RemovingWays: {
        return editMode.relationEdit.clicked.wayIds.filter(wayId => {
          return !modification.selectedWayIds.includes(wayId)
        })
      }
      default: {
        throw new Error('Unknown modification type: ' + editMode.relationEdit.modification.type)
      }
    }
  }

  const getUpdatedFeatures = () => {
    const relation = {
      osmId: editMode.relationEdit.clicked.relationId,
      tags: editMode.relationEdit.clicked.tags
    }
    const sourceData = map.getSource(editMode.roadNetwork.sourceId)._data

    return sourceData.features.map(feature => {
      const modification = editMode.relationEdit.modification
      const wayId = feature.properties['@id']
      if (!modification.selectedWayIds.includes(wayId)) {
        return feature
      }
      const propertiesClone = { ...feature.properties } // clone as properties cannot be modified

      // Add or remove relation to/from ways
      const relations = feature.properties.relations || []
      if (modification.type === RelationModifications.AddingWays) {
        propertiesClone.relations = relations.concat(relation)
      } else if (modification.type === RelationModifications.RemovingWays) {
        propertiesClone.relations = feature.properties.relations.filter(
          rel => rel.osmId !== editMode.relationEdit.clicked.relationId
        )
        if (propertiesClone.relations.length === 0) {
          delete propertiesClone.relations // required as property check is used in paint style
        }
      }

      return {
        ...feature,
        properties: propertiesClone
      }
    })
  }

  return (
    <EditRelationLegend
      relationEdit={editMode.relationEdit}
      onAdd={onAddWays}
      onRemove={onRemoveWays}
      onSave={onSave}
      onCancel={onCancel}
    />
  )
}

EditRelation.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default EditRelation
