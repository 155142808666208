import { createReducer } from '@reduxjs/toolkit'
import { setEditMode, setVisibleLayerId, setRoadNetworkStyleActive, deleteTrafficMap, deleteDifferenceMap, addRoadNetwork, addTrafficMap, addDifferenceMap, deleteRoadNetwork, setRoadNetworkStyles, setMapStyle } from '../actions/defaultActions'
import RoadProperties from '../components/sidebar/roadNetworks/RoadProperties'
import { RoadNetworkColors } from '../components/constants/Colors'

export const createRoadNetworkStyle = (property) => {
  // Highlight unsaved edits with dashed line
  const lineDashArray = [
    'case',
    ['boolean', ['get', 'edited'], false],
    ['literal', [1, 1]], // dash array for edited features
    ['literal', [1, 0]] // no dash for non-edited features
  ]
  const hasOriginalDependentOpacity = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    1.0,
    ['boolean', ['feature-state', 'clicked'], false],
    1.0,
    ['boolean', ['feature-state', 'hovered'], false],
    1.0,
    ['==', ['get', `${property.key}.original`], null], 0.3, // unchanged attribute
    1.0 // 0% transparency if the attribute has been changed
  ]

  // Unified style outside edit mode. In edit mode it's defined in the RoadProperties.
  if (property.key === RoadProperties.unified.key) {
    return {
      key: property.key,
      label: property.label,
      active: true, // Default option
      colors: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        RoadNetworkColors.Clicked,
        ['boolean', ['feature-state', 'hovered'], false],
        RoadNetworkColors.Hovered,
        // Or else:
        RoadNetworkColors.Unified
      ],
      opacity: 1.0,
      // E.g. "green" when the way was added or "yellow" when it was changed
      alternativeColors: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        RoadNetworkColors.Clicked,
        ['boolean', ['feature-state', 'hovered'], false],
        RoadNetworkColors.Hovered,
        ['==', ['get', property.key], property.options[0].value],
        property.options[0].color,
        ['==', ['get', property.key], property.options[1].value],
        property.options[1].color,
        ['==', ['get', property.key], property.options[2].value],
        property.options[2].color,
        ['==', ['get', property.key], property.options[3].value],
        property.options[3].color,
        // Or else: the API currently only appends the `unified` attribute for changed ways
        property.options[3].color
      ],
      alternativeOpacity: 1.0,
      lineDashArray
    }
  }

  // Route style.
  if (property.key === RoadProperties.relations.key) {
    const routeColors = [
      'case',
      // Before clicked to highlight full relation when shared ways with clicked relation
      ['boolean', ['feature-state', 'hovered'], false],
      // Can't use relation0Color: relation ways have different relations[0] then hovered relation
      RoadNetworkColors.Hovered,
      ['boolean', ['feature-state', 'clicked'], false],
      RoadNetworkColors.Clicked,
      // relationColor calculated by client
      ['get', 'relationColor']
      // Replace the line above with the following to have one color for all relations
      // ['has', property.key],
      // property.options[0].color,
      // Or else:
      // RoadNetworkColors.Neutral
    ]
    return {
      key: property.key,
      label: property.label,
      active: false,
      colors: routeColors,
      opacity: 1.0,
      alternativeColors: routeColors,
      alternativeOpacity: hasOriginalDependentOpacity,
      lineDashArray
    }
  }

  const propertyDependentColors = [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    RoadNetworkColors.Clicked,
    ['boolean', ['feature-state', 'hovered'], false],
    RoadNetworkColors.Hovered,
    ['==', ['get', property.key], property.options[0].value],
    property.options[0].color,
    ['==', ['get', property.key], property.options[1].value],
    property.options[1].color,
    ['==', ['get', property.key], property.options[2].value],
    property.options[2].color,
    ['==', ['get', property.key], property.options[3].value],
    property.options[3].color,
    // Or else:
    RoadNetworkColors.Unknown
  ]
  return {
    key: property.key,
    label: property.label,
    active: false,
    colors: propertyDependentColors,
    opacity: 1.0,
    // Colors and opacity for edit mode:
    alternativeColors: propertyDependentColors,
    alternativeOpacity: hasOriginalDependentOpacity,
    lineDashArray
  }
}

/**
 * Of the Redux data store.
 *
 * It's *recommended* to only store serializeable stuff (e.g. for time travel).
 */
const initialState = {
  /**
   * Mode which allows the user to change roadNetwork feature properties.
   */
  editMode: {
    active: false,
    roadNetwork: null,
    wayEdit: {
      hoveredWayId: null,
      clickedWayId: null
    },
    // In the relation edit mode, we also want to be able to select ways to be added/removed
    // from the relation. We store the clicked ways inside the relation object.
    relationEdit: {
      // the objects currently hovered by the mouse in the map
      hovered: {
        relationId: null,
        wayIds: [], // The ways that make up the relation
        tags: {} // The tags of the
      },
      // the objects marked as clicked in the map (i.e. the relation being edited)
      clicked: {
        relationId: null,
        wayIds: [], // The ways that make up the relation
        tags: {} // The tags of the relation
      },
      // Describes what modification is currently being made to the clicked relation
      modification: {
        // The `RelationModifications` type like "AddingWays" or "RemovingWays"
        type: null,
        hoveredWayId: null, // The way currently hovered by the mouse
        // The ways marked as selected in the map - to be added or removed from the relation
        selectedWayIds: []
      }
    }
  },
  /**
   * The layer which is currently shown in the map.
   */
  visibleLayerId: null,
  /**
   * The styles are available for the road network layers.
   */
  roadNetworkStyles: [
    createRoadNetworkStyle(RoadProperties.unified),
    createRoadNetworkStyle(RoadProperties.relations),
    createRoadNetworkStyle(RoadProperties.maxSpeed),
    createRoadNetworkStyle(RoadProperties.roadStyle),
    createRoadNetworkStyle(RoadProperties.surface),
    createRoadNetworkStyle(RoadProperties.surfaceQuality)
  ],
  mapStyle: 'light',
  roadNetworks: [],
  trafficMaps: [],
  differenceMaps: []
}

const rootReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEditMode, (state, action) => {
      state.editMode = action.payload
    })
    .addCase(setVisibleLayerId, (state, action) => {
      state.visibleLayerId = action.payload.visibleLayerId
    })
    .addCase(setRoadNetworkStyleActive, (state, action) => {
      const roadNetworkStyles = state.roadNetworkStyles.map(style => {
        if (style.key === action.payload) {
          style.active = true
        } else {
          style.active = false
        }
        return style
      })
      state.roadNetworkStyles = [...roadNetworkStyles]
    })
    .addCase(setRoadNetworkStyles, (state, action) => {
      state.roadNetworkStyles = action.payload
    })
    .addCase(setMapStyle, (state, action) => {
      state.mapStyle = action.payload
    })
    .addCase(addRoadNetwork, (state, action) => {
      const roadNetwork = action.payload
      if (roadNetwork.layerId === null || roadNetwork.layerId === '') {
        throw Error('ADD_ROAD_NETWORK: Empty layerId: ' + roadNetwork.layerId)
      }
      state.roadNetworks = [...state.roadNetworks, roadNetwork]
    })
    .addCase(addTrafficMap, (state, action) => {
      state.trafficMaps = [...state.trafficMaps, action.payload]
    })
    .addCase(addDifferenceMap, (state, action) => {
      state.differenceMaps = [...state.differenceMaps, action.payload]
    })
    .addCase(deleteRoadNetwork, (state, action) => {
      const roadNetworks = state.roadNetworks.filter(roadNetworks => {
        return roadNetworks.id !== action.payload
      })
      state.roadNetworks = [...roadNetworks]
      state.visibleLayerId = null
    })
    .addCase(deleteTrafficMap, (state, action) => {
      const trafficMaps = state.trafficMaps.filter(trafficMap => {
        return trafficMap.id !== action.payload
      })
      state.trafficMaps = [...trafficMaps]
    })
    .addCase(deleteDifferenceMap, (state, action) => {
      const differenceMaps = state.differenceMaps.filter(differenceMap => {
        return differenceMap.id !== action.payload
      })
      state.differenceMaps = [...differenceMaps]
    })
})

/**
 * Registered reducer which injects actions into Redux.
 *
 * @param {*} state the current state
 * @param {*} action the action to deploy onto the state
 */

// Redux Reducer handles the immutability for us
/*
roadNetworkReducer = createReducer([] / * init state * /, {
  ADD_ROAD_NETWORK: (state, action) => {
    const roadNetwork = action.payload
    state.roadNetworks.push(roadNetwork)
  }
})
*/

export default rootReducer
