import React from 'react'
import PropTypes from 'prop-types'
import ControlButton from '../ControlButton'

/**
 * A button which allows the user to log out.
 */
const LogoutButton = ({ logout }) => {
  /**
   * Defines the element injected into the container
   */
  return (
    <ControlButton
      onClick={logout}
      icon="power_settings_new"
      tooltip="Abmelden"
      sx={{ margin: '0px' }} />
  )
}

/**
 * Validates props' types
 */
LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired
}

export default LogoutButton
