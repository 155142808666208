const blue = '#2196F3'
const green = '#4CAF50'
const yellow = '#FFC107'
const red = '#F44336'
const grey = '#9E9E9E'

const RoadProperties = {
  unified: {
    key: 'unified',
    label: 'Einheitlich',
    options: [
      {
        label: 'Hinzugefügt',
        value: 'added',
        color: green
      },
      {
        label: 'Geändert',
        value: 'edited',
        color: yellow
      },
      {
        label: 'Gelöscht',
        value: 'deleted',
        color: red
      },
      {
        label: 'Unverändert',
        value: 'unchanged',
        color: grey
      }
    ]
  },
  relations: {
    key: 'relations',
    label: 'Fahrradrouten',
    options: [
      {
        label: 'Fahrradroute',
        value: 'bicycle', // RoadLegends needs this for unique keys
        // We could try to use the color from the relation but there can be two relations on one way
        color: blue
      }
    ]
  },
  maxSpeed: {
    key: 'maxSpeed',
    label: 'Höchstgeschwindigkeit des MIV',
    options: [
      {
        label: '<= 30 km/h',
        value: '<=30',
        color: green
      },
      {
        label: '31-50 km/h',
        value: '31-50',
        color: yellow
      },
      {
        label: '>50 km/h',
        value: '>50',
        color: red
      },
      {
        label: 'nA',
        value: 'nA',
        color: grey
      }
    ]
  },
  roadStyle: {
    key: 'roadStyle',
    label: 'Führungsform',
    options: [
      {
        label: 'Seperat geführter Radweg',
        value: 'BicyclePath',
        color: green
      },
      {
        label: 'Radfahr- oder Schutzstreifen',
        value: 'MarkedPath',
        color: yellow
      },
      {
        label: 'Mischverkehr',
        value: 'Road',
        color: red
      },
      {
        label: 'nA',
        value: 'nA',
        color: grey
      }
    ]
  },
  surface: {
    key: 'surface',
    label: 'Oberflächenbeschaffenheit',
    options: [
      {
        label: 'Komfortlevel 1 (z.B. Asphalt)',
        value: 'comfort_1',
        color: blue
      },
      {
        label: 'Komfortlevel 2 (z.B. verdichtete Deckschicht)',
        value: 'comfort_2',
        color: green
      },
      {
        label: 'Komfortlevel 3 (z.B. Kopfsteinpflaster)',
        value: 'comfort_3',
        color: yellow
      },
      {
        label: 'Komfortlevel 4 (z.B. Gras, loser grober Schotter)',
        value: 'comfort_4',
        color: red
      }
    ]
  },
  surfaceQuality: {
    key: 'surfaceQuality',
    label: 'Qualität des Fahrbahnbelages',
    options: [
      {
        label: 'Gut',
        value: 'Good',
        color: green
      },
      {
        label: 'Mittel',
        value: 'Medium',
        color: yellow
      },
      {
        label: 'Schlecht',
        value: 'Bad',
        color: red
      },
      {
        label: 'nA',
        value: 'nA',
        color: grey
      }
    ]
  }
}

export default RoadProperties
