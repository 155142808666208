import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { setVisibleLayerId, deleteTrafficMap, deleteDifferenceMap } from '../../../actions/defaultActions'
import { deleteDifference, deleteSimulation } from '../../DataApi'
import CustomCheckbox from '../../CustomCheckbox'
import ControlButton from '../../ControlButton'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'

const StyleBox = styled.div`
  border:solid;
  border-width:1px;
  border-color:lightgrey;

  width:94%;
  margin:10px;
  padding:10px;

  font-size: 14pt;
  display: ${props => props.$trafficMaps.length > 0 ? 'block' : 'none'};
`

const StyleHead = styled.div`
  font-size:18pt;
  color: #222A35;
  padding-bottom:10px;
`

const DeleteButtonWrapper = styled.div`
  display: inline;
  padding-left: 10px;
`

const TrafficMaps = ({ map, logout }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const differenceMaps = useSelector((state) => state.differenceMaps)

  const onCheckboxChanged = (e, trafficMapLayerId) => {
    const checkbox = e.target
    const makeVisible = checkbox.checked
    const newVisibleLayerId = makeVisible ? trafficMapLayerId : null
    dispatch(setVisibleLayerId(newVisibleLayerId, map, visibleLayerId, roadNetworkStyles))
  }

  const deleteTrafficMapHandler = async (trafficMap) => {
    // Remove DifferenceMaps bound to that TrafficMap
    const deleteDifferencePromises = differenceMaps.map((differenceMap) => {
      if (differenceMap.roadNetworkId1 === trafficMap.roadNetworkId ||
          differenceMap.roadNetworkId2 === trafficMap.roadNetworkId) {
        return deleteDifference(
          dispatch,
          defaultErrorHandling,
          logout,
          differenceMap.roadNetworkId1,
          differenceMap.roadNetworkId2
        ).then(() => {
          map.removeLayer(differenceMap.layerId)
          map.removeSource(differenceMap.sourceId)
          dispatch(deleteDifferenceMap(differenceMap.id))
        })
      }
      return null
    }).filter(Boolean) // Filter undefined values (when if condition is not met)

    // Wait for all differenceMaps to be deleted
    await Promise.all(deleteDifferencePromises)

    // Remove trafficMap
    await deleteSimulation(dispatch, defaultErrorHandling, logout, trafficMap.roadNetworkId)
    map.removeLayer(trafficMap.layerId)
    map.removeSource(trafficMap.sourceId)
    dispatch(deleteTrafficMap(trafficMap.id))
  }

  /**
   * Renders the traffic maps list.
   *
   * Must be a function or else it's only rendered once.
   */
  const trafficMapsList = (trafficMaps, editMode, visibleLayerId) => (
    trafficMaps.length
      ? (
          trafficMaps.map(trafficMap => {
            const showDeleteButton = trafficMap.layerId === visibleLayerId
            return (
          <div key={trafficMap.id}>
            <CustomCheckbox
              id={trafficMap.id}
              label={trafficMap.name}
              value={trafficMap.layerId}
              checked={trafficMap.layerId === visibleLayerId}
              onChange={(e) => onCheckboxChanged(e, trafficMap.layerId) }
              disabled={editMode.active}
              sx={{ padding: '3px 9px 3px 9px' }} />
            {showDeleteButton && (
              <DeleteButtonWrapper>
                <ControlButton
                  id='deleteTrafficMapButton'
                  width='50px'
                  height='32px'
                  onClick={() => { deleteTrafficMapHandler(trafficMap) }}
                  icon='delete' />
              </DeleteButtonWrapper>
            )}
          </div>
            )
          })
        )
      : (
      <p className="center">Keine Einträge vorhanden</p>
        )
  )

  /**
   * Defines the element injected into the container
   */
  return (
    <StyleBox $trafficMaps={trafficMaps}> {/* transient prop */}
      <StyleHead>Verkehrsmengen</StyleHead>
      <div>
        { trafficMapsList(trafficMaps, editMode, visibleLayerId) }
      </div>
    </StyleBox>
  )
}

/**
 * Validates props' types
 */
TrafficMaps.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default TrafficMaps
