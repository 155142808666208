/**
 * Mapbox styles representing different basemaps.
 */
export const BasemapStyles = {
  Light: {
    key: 'light',
    label: 'Hell',
    style: 'mapbox://styles/mapbox/light-v11'
  },
  Outdoors: {
    key: 'outdoors',
    label: 'Outdoor',
    style: 'mapbox://styles/mapbox/outdoors-v12'
  },
  Streets: {
    key: 'streets',
    label: 'Straßen',
    style: 'mapbox://styles/mapbox/streets-v12'
  },
  Satellite: {
    key: 'satellite',
    label: 'Satellit',
    style: 'mapbox://styles/mapbox/satellite-streets-v12'
  },
  Dark: {
    key: 'dark',
    label: 'Dunkel',
    style: 'mapbox://styles/mapbox/dark-v11'
  },
  None: {
    key: 'none',
    label: 'Keine Karte',
    style: null
  }
}
