import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ControlButton from '../../ControlButton'

const LegendBox = styled.div`
  position:absolute;
  width: 340px;
  height: 180px;
  background-color: white;
  left: 360px;
  bottom: 300px;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
`

export const Header = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
`

export const Description = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`

const LeftButton = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 20px;
  left: 20px;
`

const RightButton = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
`

/**
 * Presentational component for the legend of the edit relation mode.
 *
 * @param {*} relationEdit The relation that is currently being edited.
 * @param {*} onAdd Callback function for adding a way to the relation.
 * @param {*} onRemove Callback function for removing a way from the relation.
 * @param {*} onSave Callback function for saving the changes to the relation.
 * @param {*} onCancel Callback function for cancelling the edit mode.
 * @returns The legend for the edit relation mode.
 */
const EditRelationLegend = ({ relationEdit, onAdd, onRemove, onSave, onCancel }) => {
  const modificationOptions = () => {
    return (
      <div>
        <LeftButton>
          <ControlButton
            text="Weg hinzufügen"
            icon='add'
            onClick={onAdd}
            width='148px' />
        </LeftButton>
        <RightButton>
          <ControlButton
            text="Weg entfernen"
            icon='remove'
            onClick={onRemove}
            width='142px' />
        </RightButton>
      </div>
    )
  }

  const confirmButtons = () => {
    return (
      <div>
        <Description>
          Ausgewählte Elemente: {relationEdit.modification.selectedWayIds.length}
        </Description>
        <LeftButton>
          <ControlButton
            text="Speichern"
            icon='save'
            disabled={relationEdit.modification.selectedWayIds.length === 0}
            onClick={onSave}
            width='148px' />
        </LeftButton>
        <RightButton>
          <ControlButton
            text="Abbrechen"
            icon='cancel'
            onClick={onCancel}
            width='142px' />
        </RightButton>
      </div>
    )
  }

  return (
    <LegendBox>
      { relationEdit.clicked.relationId
        ? (
          <div>
            <Header>{ relationEdit.clicked.tags.name }</Header>
            <Description>Support-Id: { relationEdit.clicked.relationId }</Description>
            { !relationEdit.modification.type ? modificationOptions() : confirmButtons() }
          </div>
          )
        : (<Header>Keine Route ausgewählt</Header>)}
    </LegendBox>
  )
}

EditRelationLegend.propTypes = {
  relationEdit: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default EditRelationLegend
