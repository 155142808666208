import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { addRoadNetwork, setVisibleLayerId } from '../../../actions/defaultActions'
import { postScenarios, getScenario } from '../../DataApi.js'
import { useSnackbarContext } from '../../SnackbarContext'
import ControlButton from '../../ControlButton'
import Dropdown from '../Dropdown'
import { MapLayers } from '../../constants/MapLayers.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers.js'
import { getLayerId } from '../../IdHelper.js'

/**
 * Allows to define new roadNetworks based on another roadNetwork.
 */
const AddRoadNetwork = ({ map, logout, addFeature }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // Redux state
  const roadNetworks = useSelector((state) => state.roadNetworks)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadNetworkStyles = useSelector((state) => state.roadNetworkStyles)

  // Local state
  const [open, setOpen] = useState(false)
  const [roadNetwork, setRoadNetwork] = useState({ name: '' })
  const [selectedDropdownLayerObject, setSelectedDropdownLayerObject] = useState(null)

  const handlePopupSubmit = async (e) => {
    e.preventDefault()
    const templateId = selectedDropdownLayerObject.id
    if (roadNetwork.name.length === 0) {
      alert('Bitte geben Sie einen Namen ein.')
      return
    }
    if (roadNetworks.find(network => network.name === roadNetwork.name) !== undefined) {
      alert('Es existiert bereits ein Netz mit diesem Namen.')
      return
    }
    // Reset popup fields and hide to prevent user interaction
    setOpen(false)
    setRoadNetwork({ name: '' })
    setSelectedDropdownLayerObject(null)

    // Create scenario
    enqueueSnackbar('Erzeuge ' + roadNetwork.name)
    const createResult = await createScenario(roadNetwork.name, templateId)
    if (createResult instanceof Error) {
      // Replace this with Sentry later
      enqueueSnackbar(createResult.info)
      console.error(createResult.stack)
      return
    }
    const scenarioId = createResult

    // Load scenario
    const scenario = (await getScenario(dispatch, defaultErrorHandling, logout, scenarioId)).data

    // Fill missing attributes
    const layerId = getLayerId(scenarioId)
    roadNetwork.id = scenarioId
    roadNetwork.editable = true
    roadNetwork.layerId = layerId
    roadNetwork.sourceId = layerId

    // add feature to map
    addFeature(map, layerId, scenario.roadNetwork, MapLayers.RoadNetwork)

    // Add network to app
    dispatch(addRoadNetwork(roadNetwork))

    // From UIX view it makes sense to show the new layer
    // but this currently only fires a redux action but not the reducer -->
    dispatch(setVisibleLayerId(layerId, map, visibleLayerId, roadNetworkStyles))
    closeSnackbar()
  }

  /**
   * Creates a new scenario at the API and returns it's identifier if successful.
   *
   * @param {*} roadNetworkName the name of the scenario to create
   * @param {*} templateId the id of the scenario to use as template
   * @returns the id of the scenario created or an {@code instanceof} {@code Error} if failed.
   */
  const createScenario = async (roadNetworkName, templateId) => {
    const response =
      await postScenarios(dispatch, defaultErrorHandling, logout, roadNetworkName, templateId)

    const location = response.headers.location
    if (location == null) {
      return new Error('Missing location header in server reply.')
    }
    const scenariosPath = '/scenarios/'
    const index = location.match(scenariosPath).index
    if (index == null || index === 0) {
      return new Error('Unexpected location header: ' + location)
    }
    const scenarioId = location.substr(index + scenariosPath.length)
    return scenarioId
  }

  /**
   * Updates the internal state.
   */
  const onNameChanged = (e) => {
    setRoadNetwork({ name: e.target.value })
  }

  /**
   * Updates internal state when the dropdown selection changes.
   */
  const onDropdownChange = (layerObject) => {
    setSelectedDropdownLayerObject(layerObject)
  }

  /**
   * Shows a popup for further input information.
   */
  const onAddButtonClicked = () => {
    setOpen(true)
  }

  /**
   * Defines the element injected into the container
   */
  return (
    <>
      <ControlButton
        id='addRoadNetworkButton'
        text='Neues Netz erstellen'
        width='100%'
        icon='call_split'
        onClick={onAddButtonClicked}
        sx={{ marginTop: '9px' }} />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Neues Netzwerk hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name des neuen Netzes"
            type="text"
            fullWidth
            variant="outlined"
            value={roadNetwork.name}
            onChange={onNameChanged}
          />
          <Typography variant="body2" color="red" sx={{ marginBottom: '20px' }}>
            Nur Buchstaben+Zahlen, keine Sonderzeichen benutzen!
          </Typography>
          <Dropdown
            width='100%'
            triggerText='Netzgrundlage wählen'
            selectedLayerObject={selectedDropdownLayerObject}
            layerObjects={roadNetworks.map(roadNetwork => {
              const layerObject = {}
              layerObject.id = roadNetwork.id
              layerObject.name = roadNetwork.name
              layerObject.layerId = roadNetwork.id
              layerObject.roadNetworkId = roadNetwork.id
              return layerObject
            })}
            onChangeHandler={onDropdownChange} />
        </DialogContent>
        <DialogActions sx={{ margin: '0px 15px 10px 15px' }}>
          <ControlButton
            width='300px'
            sx={{ margin: '0px' }}
            text='Speichern'
            disabled={selectedDropdownLayerObject === null}
            onClick={handlePopupSubmit}
          />
          <ControlButton
            width='300px'
            sx={{ margin: '0px' }}
            text='Schließen'
            onClick={() => setOpen(false)} />
          </DialogActions>
      </Dialog>
    </>
  )
}

/**
 * Validates props' types
 */
AddRoadNetwork.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default AddRoadNetwork
